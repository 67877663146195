.desktop_app {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sky_img,
.building_main_img {
  width: 100%;
}

.sky_img {
  z-index: -1;
}

.building-main-img {
  top: 0;
  position: absolute;
  z-index: 1;
}

.sky_img {
  top: -6vw;
  left: 0;
  position: fixed;
}

.layer_sky_mb {
  width: 100%;
  height: 90vh;
  position: fixed;
  background-image: url("../public/pics/sky-mobile.png");
  background-size: cover;
  background-position: bottom;
  z-index: -1;
}

@media only screen and (max-width: 1024px) {
  .desktop_app {
    display: none;
  }
}

@media only screen and (min-width: 1025px) {
  .mobile_app {
    display: none;
  }

  body {
    height: 100vh;
    display: block;
  }

  .desktop_app {
    position: relative;
    width: 100%;
  }
}
