.close {
  font-size: 8vw;
  position: fixed;
  top: 2%;
  right: 5%;
  cursor: pointer;
  transition: all 0.2s;
}

.close:hover {
  scale: 1.2;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.overlay_terms,
.overlay_privacy,
.overlay_faq {
  margin: 0;
  padding: 12%;
  padding-bottom: 20%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background-color: #00064a;
  color: #f4316d;
  font-size: 3vw;
  font-family: "Tektur", sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  animation: fade-in 0.5s ease-in;
}

.overlay_faq h1 {
  margin-bottom: 2%;
}

.overlay_terms h1 {
  margin-bottom: 2%;
}

.overlay_privacy h1 {
  margin-bottom: 2%;
}

.overlay_faq h2,
.overlay_faq p {
  padding: 3% 0;
}

.overlay_privacy h2,
.overlay_privacy p {
  padding: 3% 0;
}

.overlay_terms h2,
.overlay_terms p {
  padding: 3% 0;
}

@media only screen and (min-width: 744px) {
  .overlay_terms,
  .overlay_privacy,
  .overlay_faq {
    font-size: 2vw;
    padding: 12%;
  }

  .overlay_faq h1 {
    margin-bottom: 3%;
  }

  .overlay_terms h1 {
    margin-bottom: 3%;
  }

  .overlay_privacy h1 {
    margin-bottom: 3%;
  }

  .overlay_faq h2,
  .overlay_faq p {
    padding: 2% 0;
  }

  .overlay_privacy > h2,
  .overlay_privacy p {
    padding: 2% 0;
  }

  .overlay_terms > h2,
  .overlay_terms p {
    padding: 2% 0;
  }
}

@media only screen and (min-width: 1025px) {
  .overlay_terms,
  .overlay_privacy,
  .overlay_faq {
    font-size: 1vw;
    padding: 12%;
  }

  .close {
    font-size: 3vw;
  }

  .overlay_faq h2,
  .overlay_faq p {
    padding: 1% 0;
  }

  .overlay_privacy h2,
  .overlay_privacy p {
    padding: 1% 0;
  }

  .overlay_terms h2,
  .overlay_terms p {
    padding: 1% 0;
  }
}
