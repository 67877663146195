.testimonial_mb {
  position: relative;
  margin-top: -4vw;
  z-index: 20;
  width: 100%;
}

.testimonial_bg_mb {
  display: inline-block;
  width: 100%;
}

.testimonial_text_mb {
  top: 30vw;
  left: 11.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 75vw;
  height: 23vw;
  z-index: 10;
  text-align: center;
  transition: opacity 0.5s;
}

.testimonial_text_p_mb,
.testimonial_text_p_name_mb,
.testimonial_text_p_des_mb {
  font-size: 2.3vw;
  color: #f02c93;
  filter: drop-shadow(0 0.2vw 1.5vw);
  margin-top: 0;
  margin-bottom: 0;
}

.testimonial_text_p_name_mb {
  margin-top: 2%;
}
