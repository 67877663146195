.reach_out_container_mb {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%);
  width: 40%;
  text-align: center;
  perspective: 400px;
  z-index: 1000;
}

.reach_out_button_mb {
  position: absolute;
  font-size: 5vw;
  width: 90%;
  color: #f02c93;
  border: #f02c93 2px solid;
  filter: drop-shadow(0 0.2vw 1.5vw #f02c93);
  left: 3%;
  transform: rotate3d(1, 0, 0, 30deg);
  top: 90%;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.reach_out_button_mb:active {
  transform: rotate3d(1, 0, 0, 0deg);
}

@media only screen and (min-width: 600px) {
  .reach_out_container_mb {
    width: 25%;
    top: 0%;
  }
  .reach_out_button_mb {
    font-size: 3vw;
  }
}
