a {
  text-decoration: none;
  color: #f02c93;
}

.contact_form {
  text-align: center;
  position: absolute;
  top: 84.1%;
  left: 42.65%;
  width: 19%;
}

.form_heading {
  color: #f02c93;
  font-weight: 500;
  font-size: 1.2vw;
  filter: drop-shadow(0 0.1vw 0.5vw #f02c93);
  margin-left: 3%;
}

input {
  width: 100%;
}

.neon_input {
  padding: 2%;
  margin: 1.5% 0;
  border: none;
  border-radius: 0.5vw;
  color: #fff;
  font-family: "Tektur";
  font-weight: 6;
  font-size: 1vw;
  background-color: #f02c93;
  filter: brightness(0.75);
  transition: all 0.2s;
}

.neon_input:hover {
  filter: brightness(1);
  box-shadow: 0 0.1vw 0.5vw #f02c93;
}

.neon_input:focus {
  filter: brightness(1);
  box-shadow: 0 0.1vw 0.5vw #f02c93;
  outline: #fd54ae 3px solid;
}

.neon_input:autofill,
.neon_input:-webkit-autofill-strong-password,
.neon_input:-webkit-autofill-strong-password-viewable,
.neon_input:-webkit-autofill-and-obscured {
  background-color: #f02c93;
  color: #fff;
}

textarea {
  width: 100%;
  resize: none;
}

.neon_input::placeholder {
  color: #fff;
}

.neon_button {
  border: none;
  margin-top: 1%;
  width: 50%;
  border-radius: 100px;
  background-color: #f02c93;
  color: #fff;
  cursor: pointer;
  font-family: "tektur";
  font-size: 1vw;
  font-weight: 500;
  transition: all 0.2s;
  filter: brightness(0.75);
}

.neon_button:hover {
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw #f02c93);
}

.footer_options {
  position: absolute;
  cursor: pointer;
  color: #f02c93;
  font-size: 1vw;
  transition: all 0.2s;
  filter: brightness(0.75);
}

.footer_options:hover {
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw #f02c93);
}

.footer_scroll_container {
  position: absolute;
  top: 87%;
  left: 65.25%;
  width: 6%;
}

.scroll_text_dt {
  width: 100%;
  filter: drop-shadow(0 0.1vw 0.5vw #00eaff);
  transition: all 0.2s;
}

.scroll_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}

.footer_scroll_arrow_container {
  position: absolute;
  top: 86.15%;
  left: 72%;
  width: 3.5%;
}

.scroll_arrow {
  filter: drop-shadow(0 0.1vw 0.4vw #f02c93);
  opacity: 1;
  animation: flicker 2s linear reverse infinite;
}

@keyframes flicker {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.6;
  }
  15% {
    opacity: 0.85;
  }
  25% {
    opacity: 0.5;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.25;
  }
  45% {
    opacity: 0.5;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 0.85;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.footer_options_faq {
  top: 88.3%;
  left: 32.25%;
}

.footer_options_pp {
  top: 88.3%;
  left: 67.2%;
}

.footer_options_tos {
  top: 90.3%;
  left: 29.2%;
}

.footer_options_about {
  top: 90.3%;
  left: 66.9%;
}

.social_buttons_row_top,
.social_buttons_row_bottom {
  position: absolute;
  display: flex;
  left: 47.5%;
}

.social_buttons_row_top {
  bottom: 6%;
}

.social_buttons_row_bottom {
  bottom: 4.9%;
}

.social_button {
  position: relative;
  width: 3vw;
  cursor: pointer;
}

.social_normal,
.social_glow {
  width: 100%;
}

.social_glow {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.social_button:hover > .social_glow {
  opacity: 1;
}

.footer_fan {
  position: absolute;
  top: 82.78%;
  left: 73.1%;
  width: 4%;
  z-index: 11;
}

.rotate_fan {
  transform-origin: center;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1600px) {
  .footer_scroll_container {
    top: 86.9%;
  }
}

@media only screen and (max-width: 1300px) {
  .footer_scroll_container {
    top: 86.8%;
  }
}

@media only screen and (max-width: 1100px) {
  .footer_scroll_container {
    top: 86.75%;
  }
}
