.floor_bg {
  width: 100%;
}

.illustration_floor,
.animation_floor,
.content_floor,
.webdev_floor {
  width: 57.3%;
  min-height: 10px;
  z-index: 20;
}

.illustration_lottie_door,
.illustration_lottie,
.animation_lottie_door,
.animation_lottie,
.content_lottie_door,
.content_lottie,
.webdev_lottie_door,
.webdev_lottie {
  width: 100%;
  position: absolute;
  top: 0;
}


.illustration_floor {
  position: absolute;
  top: 20.7%;
  left: 15.3%;
}

.illustration_hidden_div {
  display: hidden;
  width: 25%;
  height: 60%;
  position: absolute;
  z-index: 10;
  top: 22%;
  left: 63%;
  cursor: pointer;
}

.animation_floor {
  position: absolute;
  top: 32.4%;
  left: 28.1%;
}

.animation_hidden_div {
  display: hidden;
  width: 25%;
  height: 60%;
  position: absolute;
  z-index: 10;
  top: 25%;
  left: 11%;
  cursor: pointer;
}

.content_floor {
  position: absolute;
  top: 44%;
  left: 15.6%;
}

.content_hidden_div {
  display: hidden;
  width: 25%;
  height: 60%;
  position: absolute;
  z-index: 10;
  top: 28%;
  left: 63.5%;
  cursor: pointer;
}

.webdev_floor {
  position: absolute;
  top: 56.25%;
  left: 26.9%;
}

.webdev_hidden_div {
  display: hidden;
  width: 26%;
  height: 60%;
  position: absolute;
  z-index: 10;
  top: 25%;
  left: 13%;
  cursor: pointer;
}
