.loading_container {
  position: fixed;
  color: #f4316d;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1b0d1c;
  z-index: 10000;
}

.invisible {
  display: none;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  height: 8vw;
}

.logo_lottie {
  transform: rotateX(180deg);
  filter: drop-shadow(0 0.1vw 0.4vw rgba(244, 49, 109));
}

.text {
  display: none;
  width: 90%;
  min-height: 6%;
  margin-top: 4%;
  opacity: 0;
  font-size: 2vw;
  text-align: center;
  filter: drop-shadow(0 0.1vw 0.4vw rgba(244, 49, 109));
  animation: opacityAnimation 1s ease-in forwards;
}

.loaded {
  display: inline-block;
}

.welcome_container {
  margin-top: 2%;
  height: 100px;
  width: 15%;
}

.welcome_button {
  text-align: center;
  opacity: 0;
  background-color: #f4316d;
  border-radius: 4px;
  padding: 4%;
  color: #fff;
  font-weight: 500;
  font-size: 1.2vw;
  filter: brightness(0.5);
  transition: all 0.2s ease-in-out;
  animation: opacityAnimation 1s ease-in 0.5s forwards;
  cursor: pointer;
}

.welcome_button:hover,
.welcome_button:focus {
  scale: 1.08;
  box-shadow: 0 0 0.8vw #f4316d;
  filter: brightness(1);
  cursor: pointer;
}

/* Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacityAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1500px) {
  .logo_container {
    width: 10vw;
    height: 10vw;
  }
}

@media screen and (max-width: 1024px) {
  .logo_container {
    width: 14vw;
    height: 14vw;
  }

  .logo_lottie {
    filter: drop-shadow(0 0.2vw 0.8vw rgba(244, 49, 109));
  }

  .loading_container {
    margin-top: 0;
  }

  .text {
    font-size: 3vw;
    padding: 4%;
    filter: drop-shadow(0 0.2vw 0.8vw rgba(244, 49, 109));
  }

  .welcome_container {
    width: 30%;
  }

  .welcome_button {
    bottom: 5%;
    padding: 8px 32px;
    font-size: 2vw;
    box-shadow: 0 0 1.6vw #f4316d;
    filter: brightness(1);
  }

  .welcome_button:hover,
  .welcome_button:focus {
    box-shadow: 0 0 1.6vw #f4316d;
  }
}

@media screen and (max-width: 800px) {
  .welcome_container {
    width: 40%;
  }

  .welcome_button {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 600px) {
  .logo_container {
    width: 20vw;
    height: 20vw;
  }

  .welcome_container {
    width: 40%;
  }

  .welcome_button {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 500px) {
  .logo_lottie {
    filter: drop-shadow(0 0.3vw 1.2vw rgba(244, 49, 109));
  }

  .text {
    font-size: 4vw;
    filter: drop-shadow(0 0.3vw 1.2vw rgba(244, 49, 109));
  }

  .welcome_container {
    width: 50%;
  }

  .welcome_button {
    font-size: 3vw;
    box-shadow: 0 0 2.4vw #f4316d;
  }

  .welcome_button:hover,
  .welcome_button:focus {
    box-shadow: 0 0 2.4vw #f4316d;
  }
}
