.mtt_container_dt {
  position: relative;
  display: flex;
}

.mtt_container_mb {
  position: relative;
  display: none;
}

.mtt_bg_dt,
.mtt_bg_mb {
  width: 100%;
  height: 100%;
}

.exit_container {
  position: absolute;
  top: 4.8vw;
  left: 34.5vw;
  width: 14.5%;
}

.exit_container_mb {
  position: absolute;
  top: 9.6vw;
  left: 10.5vw;
  width: 40%;
}

.vending_machine_container {
  position: absolute;
  top: 19.2%;
  left: 16.3%;
  width: 10%;
}

.bar_container {
  position: absolute;
  top: 17.5%;
  left: 39%;
  width: 50%;
}

.bar_container_mb {
  position: absolute;
  top: 33%;
  left: 15%;
  width: 80%;
}

.table_left_container {
  position: absolute;
  top: 31.5%;
  left: 0;
  width: 50%;
}

.table_left_container_mb {
  position: absolute;
  top: 46%;
  left: 0;
  width: 70%;
}

.table_right_container {
  position: absolute;
  top: 33.5%;
  right: 0;
  width: 50%;
}

.table_right_container_mb {
  position: absolute;
  top: 43.3%;
  right: 0;
  width: 60%;
}

.debanjana_container,
.debanjana_container_mb,
.debidas_container,
.debidas_container_mb,
.sayok_container,
.sayok_container_mb,
.parijat_container,
.parijat_container_mb,
.souradip_container,
.souradip_container_mb,
.arka_container,
.arka_container_mb,
.suchorita_container,
.suchorita_container_mb,
.subhajit_container,
.subhajit_container_mb,
.purnima_container,
.purnima_container_mb,
.sudarshan_container,
.sudarshan_container_mb {
  position: absolute;
  cursor: pointer;
}

.debanjana_container {
  width: 10vw;
  height: 16vw;
  top: 9%;
  left: 0;
}

.debanjana_container_mb {
  width: 15%;
  height: 70%;
  right: 15%;
  top: 10%;
}

.sayok_container {
  width: 8vw;
  height: 18vw;
  top: 18%;
  left: 0;
}

.sayok_container_mb {
  width: 20%;
  height: 40%;
  right: 40%;
  top: 12%;
}

.debidas_container {
  width: 8vw;
  height: 18vw;
  top: 18%;
  left: 20%;
}

.debidas_container_mb {
  width: 12%;
  height: 30%;
  top: 12%;
  left: 7%;
}

.parijat_container {
  width: 10vw;
  height: 18vw;
  top: 20%;
  left: 55%;
}

.parijat_container_mb {
  width: 15%;
  height: 40%;
  top: 12%;
  left: 20%;
}

.souradip_container {
  width: 7vw;
  height: 18vw;
  top: 20%;
  right: 3%;
}

.souradip_container_mb {
  width: 10%;
  height: 40%;
  top: 10%;
  right: 5%;
}

.arka_container {
  width: 7vw;
  height: 12vw;
  top: 0;
  left: 9%;
}

.arka_container_mb {
  width: 25%;
  height: 20%;
  top: 0;
  left: 0;
}

.suchorita_container {
  width: 10vw;
  height: 12vw;
  top: 5%;
  left: 38%;
}

.suchorita_container_mb {
  width: 20%;
  height: 20%;
  top: 4%;
  left: 42%;
}

.subhajit_container {
  width: 10vw;
  height: 16vw;
  top: 6%;
  right: 20%;
}

.subhajit_container_mb {
  width: 20%;
  height: 30%;
  top: 6%;
  right: 15%;
}

.purnima_container {
  width: 12vw;
  height: 18vw;
  top: 1%;
  left: 6%;
}

.purnima_container_mb {
  width: 25%;
  height: 25%;
  top: 1%;
  left: 20%;
}

.sudarshan_container {
  width: 16vw;
  height: 20vw;
  top: 7%;
  right: 15%;
}

.sudarshan_container_mb {
  width: 40%;
  height: 40%;
  top: 8%;
  right: 0;
}

/* MEET THE TEAM POPUP STYLE */

.mtt_popup_container {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  animation: fade-in-blur 1.5s ease-out forwards;
  -webkit-animation: fade-in-blur 1.5s ease-out forwards;
  overflow: hidden;
}

.close_overlay {
  animation: fade-out-blur 0.5s ease-out forwards;
}

.close {
  position: absolute;
  font-size: 3vw;
  color: #00eaff;
  filter: brightness(0.5);
  user-select: none;
  -webkit-user-select: none;
  top: -4%;
  right: 0;
  z-index: 10;
  transition: all 0.2s;
  opacity: 0;
  animation: fade-in 1s ease-out 1s forwards;
  cursor: pointer;
}

.close:hover {
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw #00eaff);
}

.popup_lottie_container {
  position: relative;
  width: 30%;
  animation: bounce-scale 1.2s linear forwards;
}

.popup_bg {
  /* position: absolute; */
  display: flex;
}

.popup_lottie {
  position: absolute;
  top: 10.7%;
  left: 49%;
  width: 50%;
  transform: translateX(-50%);
  animation: fade-in 1s ease-out forwards;
}

.close_popup {
  animation: scale-down 0.3s ease forwards;
}

.popup_arrow {
  position: absolute;
  width: 12%;
  padding: 3%;
  bottom: 5%;
  filter: brightness(0.5);
  transition: all 0.2s;
  cursor: pointer;
}

.popup_left_arrow {
  left: 3.5%;
}

.popup_right_arrow {
  right: 3.5%;
}

.popup_arrow:hover {
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw #00eaff);
}

.popup_name,
.popup_designation,
.popup_description_container {
  position: absolute;
  left: 50%;
  font-weight: 500;
  color: #00eaff;
  transform: translateX(-50%);
}

.popup_name, .popup_designation, .popup_description {
  padding: 5%;
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw #97dfff);
}

.popup_name {
  display: flex;
  width: 100%;
  bottom: 34.5%;
  font-size: 1.25vw;
  align-items: center;
  justify-content: center;
}

.popup_designation {
  bottom: 20%;
  font-size: 1.25vw;
}

.popup_description_container {
  position: absolute;
  display: flex;
  width: 58%;
  height: 20%;
  bottom: 4.5%;
  align-items: center;
  justify-content: center;
}

.popup_description {
  bottom: 12%;
  text-align: center;
  font-size: 1vw;
  font-weight: 500;
  margin: 0;
}

.popup_name,
.popup_designation,
.popup_description,
.popup_arrow {
  display: flex;
  opacity: 0;
  animation: fade-in 1s ease forwards;
}

.invisible {
  display: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-blur {
  0% {
    opacity: 0;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }

  100% {
    opacity: 1;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}

@keyframes fade-out-blur {
  0% {
    opacity: 1;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }

  100% {
    opacity: 0;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
}

@keyframes bounce-scale {
  0% {
    transform: scale(0);
  }
  2.8% {
    transform: scale(0.077);
  }
  5.5% {
    transform: scale(0.252);
  }
  8.33% {
    transform: scale(0.484);
  }
  11.11% {
    transform: scale(0.746);
  }
  13.9% {
    transform: scale(1);
  }
  16.7% {
    transform: scale(1.076);
  }
  19.44% {
    transform: scale(1.12);
  }
  22.22% {
    transform: scale(1.137);
  }
  25% {
    transform: scale(1.136);
  }
  27.78% {
    transform: scale(1.122);
  }
  30.55% {
    transform: scale(1.1);
  }
  33.33% {
    transform: scale(1.077);
  }
  36.11% {
    transform: scale(1.054);
  }
  38.88% {
    transform: scale(1.033);
  }
  41.67% {
    transform: scale(1.016);
  }
  44.44% {
    transform: scale(1.003);
  }
  47.22% {
    transform: scale(0.994);
  }
  50% {
    transform: scale(0.988);
  }
  52.78% {
    transform: scale(0.986);
  }
  55.56% {
    transform: scale(0.985);
  }
  58.33% {
    transform: scale(0.987);
  }
  61.11% {
    transform: scale(0.989);
  }
  63.89% {
    transform: scale(0.991);
  }
  66.67% {
    transform: scale(0.994);
  }
  69.44% {
    transform: scale(0.996);
  }
  72.22% {
    transform: scale(0.998);
  }
  75% {
    transform: scale(0.999);
  }
  77.78% {
    transform: scale(1);
  }
  80.55% {
    transform: scale(1.001);
  }
  83.33% {
    transform: scale(1.001);
  }
  86.11% {
    transform: scale(1.002);
  }
  88.89% {
    transform: scale(1.001);
  }
  91.67% {
    transform: scale(1.001);
  }
  94.44% {
    transform: scale(1.001);
  }
  97.44% {
    transform: scale(1.001);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-down {
  0% {
    scale: 1;
    opacity: 1;
  }

  100% {
    scale: 0;
    opacity: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .mtt_container_dt {
    display: none;
  }

  .mtt_container_mb {
    display: flex;
  }

  .popup_lottie_container {
    position: absolute;
    width: 80%;
  }

  .close {
    font-size: 8vw;
    filter: brightness(0.75) drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }

  .close:hover {
    filter: brightness(0.75) drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }

  .close:active {
    filter: brightness(1) drop-shadow(0 0.2vw 1vw #00eaff);
  }

  .popup_name {
    top: 48%;
    font-size: 3vw;
  }

  .popup_designation {
    top: 56.2%;
    font-size: 3vw;
  }

  .popup_description_container {
    height: 20%;
    bottom: 4.8%;
  }

  .popup_description {
    font-size: 2.4vw;
    bottom: 11.7%;
  }

  .popup_arrow {
    bottom: 5%;
  }

  .popup_arrow {
    filter: brightness(0.5) drop-shadow(0);
  }

  .popup_arrow:hover {
    filter: brightness(0.5) drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }

  .popup_arrow:active {
    filter: brightness(1) drop-shadow(0 0.1vw 1vw #00eaff);
  }
}

@media only screen and (max-width: 500px) {
  .close {
    font-size: 10vw;
  }
}
