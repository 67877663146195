.illustration_floor_mb,
.animation_floor_mb,
.content_floor_mb,
.webdev_floor_mb {
  width: 100%;
  position: relative;
}

.floor_bg_mb {
  width: 100%;
}

.floor_lottie_mb {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 9;
}

.door_lottie_mb {
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
}

.illustration_floor_mb {
  margin-top: -1.6vw;
}

.animation_floor_mb {
  margin-top: -4vw;
}

.content_floor_mb {
  margin-top: -7.1vw;
}

.webdev_floor_mb {
  margin-top: -4.5vw;
}

.illustration_hidden_div_mb,
.animation_hidden_div_mb,
.content_hidden_div_mb,
.webdev_hidden_div_mb {
  display: hidden;
  width: 30%;
  height: 20%;
  position: absolute;
  z-index: 99;
  top: 20%;
}

.illustration_hidden_div_mb {
  left: 44%;
}

.animation_hidden_div_mb {
  left: 18%;
}

.content_hidden_div_mb {
  right: 22%;
}

.webdev_hidden_div_mb {
  left: 10%;
}

.illustration_fan {
  position: absolute;
  bottom: 7.7vw;
  left: 15.2vw;
  width: 8%;
  z-index: 11;
}

.content_fan {
  position: absolute;
  bottom: 6.5vw;
  right: 8.3vw;
  width: 8%;
  z-index: 11;
}

.animation_fan,
.webdev_fan {
  display: none;
}

.rotate_fan {
  transform-origin: center;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
