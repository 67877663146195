.footer_mb {
  position: relative;
  z-index: 30;
  margin-top: -6vw;
  width: 100%;
  display: flex;
}

.footer_bg_mb {
  width: 100%;
  height: 100%;
}

.contact_form_mb {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 64%;
  top: 18%;
  left: 50%;
  align-items: center;
  height: 38%;
  transform: translateX(-51%);
}

.contact_heading_mb {
  color: #f02c93;
  font-weight: 500;
  font-size: 4vw;
  filter: drop-shadow(0 0.2vw 1.5vw);
}

.neon_input_mb {
  width: 100%;
  padding: 2%;
  margin: 2% 0;
  border: none;
  border-radius: 1vw;
  color: #fff;
  font-family: "Tektur";
  font-weight: 400;
  font-size: 3vw;
  background-color: #f02c93;
  filter: brightness(0.75);
  transition: all 0.2s;
}

.textarea_mb {
  width: 100%;
  flex-grow: 1;
  transition: all 0.2s;
}

.neon_input_mb::placeholder,
.textarea_mb::placeholder {
  color: #fff;
}

.neon_input_mb:focus,
.textarea_mb:focus {
  outline: #fd54ae 3px solid;
  filter: brightness(1);
  box-shadow: 0 0.2vw 1.5vw #f02c93;
}

.neon_button_mb {
  margin-top: 5%;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 50%;
  border-radius: 100px;
  background-color: #f02c93;
  color: #fff;
  filter: brightness(1);
  box-shadow: 0 0.2vw 1.5vw #f02c93;
  cursor: pointer;
  font-family: "tektur";
  font-size: 3vw;
  font-weight: 500;
  transition: all 0.2s;
}

.footer_scroll_container_mb {
  position: absolute;
  top: 66%;
  left: 37.7%;
  width: 23%;
}

.scroll_text_mb {
  width: 100%;
  filter: drop-shadow(0 0.1vw 1vw #00eaff);
}

.scroll_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.footer_scroll_arrow_left_mb,
.footer_scroll_arrow_right_mb {
  position: absolute;
  top: 63.8%;
  width: 8%;
  filter: drop-shadow(0 0.2vw 1vw #f02c93);
}

.footer_scroll_arrow_left_mb {
  left: 26%;
  transform: scaleX(-1);
  opacity: 1;
}

.footer_scroll_arrow_right_mb {
  right: 27.7%;
  opacity: 1;
}

@keyframes flickerLeft {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0.2;
  }
  10% {
    opacity: 0.25;
  }
  15% {
    opacity: 0.35;
  }
  25% {
    opacity: 0.2;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.25;
  }
  45% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.35;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}



.footer_buttons_container_mb {
  position: absolute;
  display: grid;
  width: 83%;
  height: 14%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  bottom: 15.8%;
  row-gap: 25%;
  column-gap: 10%;
  align-items: center;
  justify-items: center;
  color: #f02c93;
  left: 50%;
  transform: translate(-50.2%);
  font-size: 3vw;
  font-weight: 500;
}

.faq_mb,
.privacy_mb,
.terms_mb,
.mtt_mb {
  filter: drop-shadow(0 0.2vw 1.5vw);
  z-index: 10;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #f02c93;
}

.social_button_mb {
  position: absolute;
  bottom: 7%;
  width: 12%;
}

.social_button_mb > img {
  width: 100%;
}

.social_glow_mb {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.2s;
}

.social_button_mb:hover > .social_glow_mb {
  opacity: 1;
  cursor: pointer;
}

.insta_mb {
  left: 62.5%;
}

.lottie_mb {
  left: 25%;
}

.youtube_mb {
  left: 37.5%;
}

.behance_mb {
  left: 50%;
}

.upwork_mb {
  left: 12.5%;
}

.linkedin_mb {
  left: 75%;
}

@media only screen and (max-width: 500px) {
  .footer_scroll_container_mb {
    top: 65.3%;
  }
}
