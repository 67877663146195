.reach_out_container {
  position: absolute;
  top: 2%;
  width: 10%;
  right: 6%;
  transform: scale(1.5);
  z-index: 99;
}

.reach_out_lottie {
    width: 70%;
    margin-left: 16%;
}

.reach_out_button {
  width: 100%;
  position: relative;
  margin-top: -50%;
}

.reach_out_button_link {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 23%;
  left: 25%;
  z-index: 1000;
  cursor: pointer;
}

.reach_out_normal {
  width: 100%;
}

.reach_out_glow {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
}

.reach_out_button_link:hover ~ .reach_out_glow {
  opacity: 1;
}
