.portfolio_container_holder {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  animation: fade-in-blur 1.5s ease-out forwards;
  -webkit-animation: fade-in-blur 1.5s ease-out forwards;
  overflow: hidden;
}

@keyframes fade-in-blur {
  0% {
    opacity: 0;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }

  100% {
    opacity: 1;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

.close {
  position: absolute;
  top: 6%;
  right: 6%;
  font-size: 3.5vw;
  user-select: none;
  -webkit-user-select: none;
  filter: brightness(0.5);
  transition: all 0.2s;
  z-index: 10;
  cursor: pointer;
}

.close:hover {
  scale: 1.2;
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw);
}

.portfolio_container {
  position: relative;
  width: 80vw;
  display: none;
  z-index: 10001;
  animation: fade-in 0.5s ease-out forwards;
  -webkit-animation: fade-in 0.5s ease-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.portfolio_bg_img,
.portfolio_bg_img_mb {
  width: 100%;
}

.portfolio_bg_img:hover:before {
  animation: glitch 0.2s linear infinite;
}
.portfolio_fg_img,
.portfolio_fg_img_mb {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.chevron_left,
.chevron_right {
  position: absolute;
  width: 6%;
  cursor: pointer;
  top: 46%;
  z-index: 1;
  filter: brightness(0.5);
  transition: all 0.2s ease-out;
  padding: 1.5%;
}

.chevron_left {
  left: 8%;
}

.chevron_right {
  right: 8%;
}

.chevron_left:hover,
.chevron_right:hover {
  filter: brightness(1);
  transition: all 0.2s ease-out;
}

.chevron_left:hover > svg,
.chevron_right:hover > svg {
  filter: drop-shadow(0 0 0.4vw);
}

.next_text,
.prev_text {
  position: absolute;
  font-size: 1.3vw;
  cursor: pointer;
  z-index: 99;
  filter: brightness(0.5);
  padding: 1%;
  transition: all 0.2s;
}

.next_text {
  top: 28.1%;
  right: 24%;
}

.prev_text {
  top: 65.5%;
  left: 24%;
}

.next_text:hover,
.prev_text:hover {
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw);
}

.illustration {
  color: #f7d616;
  fill: #f7d616;
  stroke: #f7d616;
}

.animation {
  color: #43dd93;
  fill: #43dd93;
  stroke: #43dd93;
}

.content {
  color: #ff39ff;
  fill: #ff39ff;
  stroke: #ff39ff;
}

.webdev {
  color: #97dfff;
  fill: #97dfff;
  stroke: #97dfff;
}

.slider_image {
  display: none;
  position: absolute;
  top: 35.13%;
  left: 22.9%;
  width: 23%;
  object-fit: cover;
  mask: url("../public/portfolio/portfolio-mask.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  cursor: pointer;
  z-index: 100;
}

.loaded {
  display: inline-block;
  animation: fade-in 0.5s ease-out forwards;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  position: absolute;
  top: 48%;
  left: 33%;
  scale: 2;
}

.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  --c1: linear-gradient(90deg, var(--c));
  --c2: linear-gradient(0deg, var(--c));
  background: var(--c1), var(--c2), var(--c1), var(--c2);
  background-size: 300% 4px, 4px 300%;
  background-repeat: no-repeat;
  animation: l11 1s infinite linear;
}

.loader:after {
  margin: 10px;
  transform: scaleX(-1);
  animation-delay: -0.25s;
}

.loader.illustration:before,
.loader.illustration:after {
  --c: #0000 calc(100% / 3), #f7d616 0 calc(2 * 100% / 3), #0000 0;
}

.loader.animation:before,
.loader.animation:after {
  --c: #0000 calc(100% / 3), #43dd93 0 calc(2 * 100% / 3), #0000 0;
}

.loader.content:before,
.loader.content:after {
  --c: #0000 calc(100% / 3), #ff39ff 0 calc(2 * 100% / 3), #0000 0;
}

.loader.webdev:before,
.loader.webdev:after {
  --c: #0000 calc(100% / 3), #97dfff 0 calc(2 * 100% / 3), #0000 0;
}

@keyframes l11 {
  0% {
    background-position: 50% 0, 100% 100%, 0 100%, 0 0;
  }
  25% {
    background-position: 0 0, 100% 50%, 0 100%, 0 0;
  }
  50% {
    background-position: 0 0, 100% 0, 50% 100%, 0 0;
  }
  75% {
    background-position: 0 0, 100% 0, 100% 100%, 0 50%;
  }
  75.01% {
    background-position: 100% 0, 100% 0, 100% 100%, 0 50%;
  }
  100% {
    background-position: 50% 0, 100% 0, 100% 100%, 0 100%;
  }
}

.slider_text_container {
  position: absolute;
  top: 34%;
  left: 51.5%;
  width: 26%;
}

.slider_text_title,
.slider_text_body {
  display: block;
}

.slider_text_title {
  font-size: 2vw;
  width: 65%;
  line-height: 1.1;
}

.slider_text_body {
  display: block;
  width: 100%;
  margin-top: 2%;
  font-size: 0.8vw;
}

.show_more_button {
  display: flex;
  position: absolute;
  width: 12%;
  bottom: 32.5%;
  right: 23%;
  cursor: pointer;
  font-size: 0.9vw;
  filter: brightness(0.5);
  padding: 1%;
  transition: filter 0.2s ease-out;
}

.show_more_button:hover {
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw);
}

.show_more_svg {
  width: fit-content;
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 4px;
}

.show_more_text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 24.25%;
  transform: translateY(-52%);
}

.slider_dots {
  display: flex;
  position: absolute;
  top: 69%;
  left: 50%;
  transform: translate(-50%);
}

.dot {
  width: 1vw;
  height: 1vw;
  margin: 0 0.3vw;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.dot_illustration {
  border: 0.2vw solid #8a220e;
}

.dot_animation {
  border: 0.2vw solid #1a444e;
}

.dot_content {
  border: 0.2vw solid #52296d;
}

.dot_webdev {
  border: 0.2vw solid #294d6b;
}

.dot_illustration.active {
  background-color: #f7d616;
}

.dot_animation.active {
  background-color: #43dd93;
}

.dot_content.active {
  background-color: #ff39ff;
}

.dot_webdev.active {
  background-color: #97dfff;
}

.tab_illustration,
.tab_animation,
.tab_content,
.tab_webdev {
  position: absolute;
  top: 90%;
  font-family: "Tektur", sans-serif;
  font-size: 1.4vw;
  cursor: pointer;
  filter: brightness(0.5);
  padding: 1%;
  transition: all 0.2s;
}

.tab_illustration {
  color: #f7d616;
  left: 5.3%;
}

.tab_animation {
  color: #43dd93;
  left: 31.5%;
}

.tab_content {
  color: #ff39ff;
  left: 58.8%;
}

.tab_webdev {
  color: #97dfff;
  left: 84.5%;
}

.tab_illustration:hover,
.tab_animation:hover,
.tab_content:hover,
.tab_webdev:hover {
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw);
}

.tab_illustration.active,
.tab_animation.active,
.tab_content.active,
.tab_webdev.active {
  filter: brightness(1) drop-shadow(0 0.1vw 0.5vw);
}

@media only screen and (max-width: 1920px) {
  .loader {
    width: 75px;
    top: 46%;
    left: 32%;
    scale: 1;
  }

  .loader:before,
  .loader:after {
    background-size: 300% 6px, 6px 300%;
  }

  .loader:after {
    margin: 15px;
  }
}

@media only screen and (max-width: 1600px) {
  .loader {
    top: 45%;
    left: 31%;
  }
}

@media only screen and (max-width: 1500px) {
  .loader {
    width: 50px;
    top: 46%;
    left: 32%;
    scale: 1;
  }

  .loader:before,
  .loader:after {
    background-size: 300% 4px, 4px 300%;
  }

  .loader:after {
    margin: 10px;
  }

  .tab_illustration,
  .tab_animation,
  .tab_content,
  .tab_webdev {
    top: 89.7%;
  }
}

@media only screen and (max-width: 1520px) {
  .next_text {
    top: 28%;
  }
  
  .prev_text {
    top: 65.4%;
  }
}

@media only screen and (max-width: 1200px) {
  .loader {
    top: 45.5%;
    left: 31.5%;
  }
  .tab_illustration,
  .tab_animation,
  .tab_content,
  .tab_webdev {
    top: 89.5%;
  }
}

@media only screen and (max-width: 1024px) {
  .portfolio_container {
    width: 70vw;
    margin-top: -8vw;
  }
  .portfolio_bg_img {
    display: none;
  }

  .portfolio_fg_img {
    display: none;
  }

  .close {
    top: 1%;
    right: 4%;
    font-size: 6vw;
    filter: brightness(1) drop-shadow(0 0.1vw 0.5vw);
  }

  .slider_image {
    top: 29.66%;
    left: 27.49%;
    width: 47.7%;
  }

  .loader {
    top: 38%;
    left: 50%;
    transform: translateX(-50%);
  }

  .slider_text_container {
    top: 19%;
    left: 23.8%;
    width: 50%;
    height: 100%;
  }

  .slider_text_title,
  .slider_text_body {
    display: block;
    font-weight: 500;
  }

  .slider_text_title {
    display: flex;
    font-size: 4vw;
    width: 65%;
    height: 10vw;
    line-height: 1.1;
    align-items: center;
  }

  .slider_text_body {
    display: block;
    width: 100%;
    font-size: 1.7vw;
    position: absolute;
    top: 34%;
  }

  .next_text,
  .prev_text {
    font-size: 2.4vw;
    filter: brightness(1);
    padding: 1%;
    font-weight: 500;
    filter: drop-shadow(0 0.1vw 0.5vw);
  }

  .next_text {
    top: 26.4%;
    right: 32.5%;
  }

  .prev_text {
    top: 49.5%;
    left: 30%;
  }

  .show_more_button {
    width: 25%;
    bottom: 27%;
    right: 23%;
    font-size: 2vw;
    font-weight: 500;
    filter: brightness(1) drop-shadow(0 0.1vw 1vw);
  }

  .show_more_button:hover {
    filter: brightness(1) drop-shadow(0 0.1vw 1vw);
  }

  .show_more_text {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 19%;
    transform: translateY(-52%);
  }

  .chevron_left,
  .chevron_right {
    width: 9%;
    top: 35%;
    z-index: 1;
    filter: brightness(1);
    transition: all 0.2s ease-out;
    padding: 1.5%;
  }

  .chevron_left {
    left: 1%;
  }

  .chevron_right {
    right: 1%;
  }

  .chevron_left > svg,
  .chevron_right > svg {
    filter: drop-shadow(0 0.1vw 0.8vw);
  }

  .chevron_left:hover > svg,
  .chevron_right:hover > svg {
    filter: drop-shadow(0 0.1vw 0.8vw);
  }

  .slider_dots {
    top: 76%;
    left: 50%;
    transform: translate(-50%);
  }

  .dot {
    width: 2vw;
    height: 2vw;
    margin: 0 0.4vw;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
  }
  .dot_illustration {
    border: 0.4vw solid #8a220e;
  }

  .dot_animation {
    border: 0.4vw solid #1a444e;
  }

  .dot_content {
    border: 0.4vw solid #52296d;
  }

  .dot_webdev {
    border: 0.4vw solid #294d6b;
  }

  .tab_illustration,
  .tab_animation,
  .tab_content,
  .tab_webdev {
    font-size: 2.4vw;
    font-weight: 500;
  }

  .tab_illustration {
    left: 21.2%;
    top: 88.9%;
  }

  .tab_animation {
    top: 88.9%;
    left: 58.2%;
  }

  .tab_content {
    top: 95%;
    left: 24%;
  }

  .tab_webdev {
    top: 95%;
    left: 59.2%;
  }
}

@media only screen and (max-width: 600px) {
  .portfolio_container {
    width: 85%;
    margin-top: -10%;
  }

  .close {
    font-size: 8vw;
  }

  .slider_text_container {
    top: 20%;
  }

  .slider_text_body {
    display: block;
    width: 100%;
    font-size: 2vw;
    position: absolute;
    top: 33%;
  }

  .loader {
    width: 50px;
    top: 36%;
    left: 48.5%;
    scale: 0.5;
  }

  .show_more_button {
    font-size: 2.4vw;
  }

  .show_more_text {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translateY(-53%);
  }

  .tab_illustration {
    left: 23%;
    top: 88.8%;
  }

  .tab_animation {
    top: 88.8%;
    left: 59.4%;
  }

  .tab_content {
    top: 94.8%;
    left: 25%;
  }

  .tab_webdev {
    top: 94.8%;
    left: 60.2%;
  }
}

@media only screen and (min-width: 1025px) {
  .portfolio_bg_img_mb {
    display: none;
  }

  .portfolio_fg_img_mb {
    display: none;
  }
}
