.testimonial_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 75.1%;
  left: 31.4%;
  width: 38vw;
  height: 8.2vw;
  z-index: 10;
  text-align: center;
  transition: opacity .5s;
}

.testimonial_text_p,
.testimonial_text_p_name,
.testimonial_text_p_des {
  font-size: 0.8vw;
  -webkit-text-size-adjust: none;
  color: #f02c93;
  filter: drop-shadow(0 0.1vw 0.5vw);
  margin-top: 0;
  margin-bottom: 0;
}

.testimonial_text_p_name {
    margin-top: 1%;
 
}


