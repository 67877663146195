html {
  scroll-behavior: smooth;
  -webkit-user-select: none;
  user-select: none;
  background-color: black;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  margin: 0;
  font-family: "Tektur", sans-serif;
  color: magenta;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: inherit;
}
