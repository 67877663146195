/* LOADING SCREEN */

.loading_container {
  background-color: #050a2b;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  gap: 2vw;
}

.loading_copy {
  width: 90%;
  text-align: center;
  font-family: "Oxanium", sans-serif;
  font-size: 3vw;
  font-weight: 500;
  filter: drop-shadow(0 0.1vw 0.4vw #ff00ff);
  opacity: 0;
  animation: fadeIn 2s ease-in 1s forwards;
}

.loading_button_container {
  width: 100%;
  height: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_button {
  font-size: 1.2vw;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  color: white;
  background-color: #ff00ff;
  padding: 0.75% 2%;
  cursor: pointer;
  opacity: 0;
  animation: fadeIn 2s ease-in 1s forwards 1;
}

.invisble {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* HERO */

.power_container {
  background-color: #050a2b;
  display: flex;
  flex-direction: column;
  padding: 5% 15%;
  align-items: center;
  font-family: "IBM Plex Mono", monospace;
  font-size: 0.9vw;
}

.cover_animation {
  width: 90%;
}

.headline_section {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 10%;
  align-items: center;
}

.headline_right_container {
  border: 2px dashed;
  text-align: center;
  padding: 8% 5%;
  background-color: rgba(244, 49, 109, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headline_button {
  width: 100%;
  display: flex;
  font-size: 1.2vw;
  font-weight: 600;
  justify-content: center;
  opacity: 0.75;
  transition: all 0.2s;
}

.headline_button > a {
  white-space: nowrap;
  background-color: #ff00ff;
  padding: 2% 5%;
  display: flex;
  text-decoration: none;
  color: white;
}

.headline_button:hover {
  opacity: 1;
}

.headline_copy_1,
.intro_copy_heading,
.right_copy_heading {
  font-size: 2vw;
  margin: 0;
  font-family: "Oxanium", sans-serif;
  font-weight: 500;
  filter: drop-shadow(0 0.1vw 0.4vw);
}

.headline_copy_2,
.headline_copy_3 {
  font-size: 1.5vw;
  margin: 0;
  font-family: "Oxanium", sans-serif;
  font-weight: 400;
  color: #c1c4d1;
  margin-bottom: 1%;
}

/* INTRO SECTION */

.intro_section {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 2vw;
  row-gap: 6vw;
  margin-bottom: 10%;
}

.intro_copy {
  grid-column: 1 / -1;
}

.intro_copy_heading {
  margin-bottom: 2%;
}

.intro_copy_line_1,
.intro_copy_line_2,
.intro_copy_line_3,
.intro_copy_line_4,
.intro_copy_line_5 {
  font-size: 1vw;
  color: #9b9daa;
}

.intro_copy_line_5 {
  font-size: 1.2vw;
  font-weight: 600;
  margin-top: 2%;
  color: #c1c4d1;
}

.intro_point_1,
.intro_point_2,
.intro_point_3 {
  align-self: center;
  color: #9b9daa;
  font-size: 0.9vw;
  width: 80%;
}

.intro_point_2 {
  text-align: right;
  justify-self: end;
}

.intro_point_2 > p > span {
  font-weight: 600;
  color: #c1c4d1;
}

.intro_lottie_1,
.intro_lottie_2,
.intro_lottie_3 {
  align-self: center;
}

.intro_box {
  margin: 0 10%;
  grid-column: 1 / -1;
  padding: 5%;
  border: 2px dashed magenta;
  background-color: rgba(244, 49, 109, 0.1);
  font-size: 0.9vw;
  color: rgba(255, 255, 255, 0.7);
}

.intro_box > p > span {
  font-weight: 600;
  color: #c1c4d1;
  font-size: 1vw;
}

.bullet_points_header {
  margin-top: 5%;
  font-weight: 600;
}

.bullet_points {
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2% auto 5%;
  gap: 1vw;
}

.bullet_point {
  display: flex;
  align-items: center;
  font-size: 0.8vw;
}

.bullet_point_check {
  width: 4%;
  margin-right: 1%;
}

/* RIGHT SECTION */

.right_section {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 2vw;
  row-gap: 5vw;
}

.right_copy {
  grid-column: 1 / -1;
}

.right_copy > p {
  font-size: 1vw;
  color: #9b9daa;
}

.label {
  font-size: 1.2vw;
  font-weight: 600;
  border: 2px dashed;
  text-align: center;
  margin: 5% 10% 0;
  padding: 1% 5%;
  background-color: rgba(244, 49, 109, 0.1);
}

.mobile_line_break {
  display: none;
}

.right_bullet_points {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.right_bullet_points > .bullet_point > .bullet_point_check {
  width: 4%;
}

.right_bullet_points > .bullet_point {
  align-items: start;
}

.right_lottie_1,
.right_lottie_2 {
  align-self: center;
}

.right_description_1 {
  color: #9b9daa;
  margin-top: 10%;
  align-self: center;
}

.right_description_2 {
  color: #9b9daa;
  text-align: right;
  align-self: center;
}

.right_ending {
  grid-column: 1 / -1;
}

.right_ending > .label {
  margin-bottom: 5%;
}

.right_ending > p {
  color: #9b9daa;
  font-size: 1vw;
}

.right_ending > p > span {
  font-weight: 600;
  color: #c1c4d1;
}

/* BOOST SECTION */

.boost_section {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  row-gap: 5vw;
  margin-top: 10%;
}

.boost_heading {
  font-size: 2vw;
  margin: 0;
  font-family: "Oxanium", sans-serif;
  font-weight: 500;
  filter: drop-shadow(0 0.1vw 0.4vw);
}

.boost_container_section_1 {
  display: grid;
  margin: 0 10%;
  grid-template-columns: 1fr 1fr;
  row-gap: 2vw;
  padding: 5%;
  border: 2px dashed #ff00ff;
  background-color: rgba(244, 49, 109, 0.1);
  color: #9b9daa;
}

.boost_copy_1 {
  grid-column: 1 / -1;
  font-size: 1vw;
}

.boost_bullet_points_1 {
  grid-column: 1 / -1;
  width: 90%;
  justify-self: center;
}

.boost_bullet_point {
  display: flex;
  align-items: start;
  font-size: 0.8vw;
  margin-bottom: 2%;
}

.boost_bullet_point_img {
  width: 2.5%;
  margin-right: 1%;
}

.boost_video_container {
  display: flex;
  justify-content: center;
}

.boost_video_container > video {
  border: 2px solid #ff00ff;
}

.boost_before_title,
.boost_after_title {
  font-size: 1.6vw;
  font-weight: 600;
  color: #ff00ff;
  font-family: "Oxanium", sans-serif;
  margin: auto;
  filter: drop-shadow(0 0.1vw 0.4vw);
}

.boost_container_section_2 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 2vw;
  color: #9b9daa;
  align-items: center;
}

.boost_bullet_points_2 {
  width: 95%;
  margin: 3% auto;
}

.boost_bullet_points_2 > .boost_bullet_point > .boost_bullet_point_img {
  width: 3.5%;
  margin-right: 1%;
}

.boost_lottie {
  width: 100%;
  align-self: center;
}

/* PACKAGES SECTION */

.packages_section {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2vw;
  row-gap: 5vw;
  margin-top: 10%;
}

.packages_heading {
  grid-column: 1 / -1;
  font-size: 2vw;
  margin: 0;
  font-family: "Oxanium", sans-serif;
  font-weight: 500;
  filter: drop-shadow(0 0.1vw 0.4vw);
}

.package_container_section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2vw;
  margin-top: 5vw;
}

.package_container {
  padding: 10%;
  border: 2px dashed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(244, 49, 109, 0.1);
}

.package_container > img {
  width: 40%;
  filter: drop-shadow(0 0.1vw 0.4vw);
}

.package_bullet_point {
  display: flex;
  align-items: start;
  font-size: 0.8vw;
  margin-bottom: 2%;
  color: #9b9daa;
}

.bullet_point_plus {
  width: 4%;
  margin-right: 2%;
  margin-top: 1%;
}

.bullet_points_container {
  margin-bottom: 10%;
}

.package_end {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

.package_preparation {
  width: 90%;
  text-align: center;
  font-weight: 600;
  border: 1px solid;
  padding: 2%;
}

.package_button {
  width: 100%;
  text-align: center;
  font-size: 1.2vw;
  font-weight: 600;
  opacity: 0.75;
  transition: all 0.2s;
  cursor: pointer;
  justify-self: center;
  margin-top: 5%;
}

.package_button > a {
  display: inline-block;
  width: 90%;
  text-decoration: none;
  color: white;
  background-color: #ff00ff;
  padding: 2% 10%;
}

.package_button:hover {
  opacity: 1;
}

/* FOOTER SECTION */

.footer_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2vw;
  margin-top: 5%;
  padding: 5% 0;
}

.footer_button {
  width: 100%;
  text-align: center;
  font-size: 1.2vw;
  font-weight: 600;
  opacity: 0.75;
  transition: all 0.2s;
  cursor: pointer;
  justify-self: center;
}

.faq_button {
  background-color: #9b9daa;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_button > a {
  display: inline-block;
  padding: 2%;
  width: 100%;
  background-color: #9b9daa;
  text-decoration: none;
  color: white;
  transition: all 0.2s;
}

.footer_button:hover {
  opacity: 1;
  background-color: #c1c4d1;
}

.footer_button:hover > a {
  color: #050a2b;
}

.form_power_contact {
  width: 60%;
  padding: 5%;
  border: 2px dashed;
  margin-top: 10%;
  background-color: rgba(244, 49, 109, 0.1);
  display: flex;
  flex-direction: column;
}

.form_heading {
  font-size: 2vw;
  margin: 0 0 2%;
  font-family: "Oxanium", sans-serif;
  font-weight: 500;
  filter: drop-shadow(0 0.1vw 0.4vw);
}

.form_label {
  font-size: 0.7vw;
}

.form_input {
  font-size: 0.8vw;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  margin: 0.25% 0 2%;
  padding: 1%;
  border-radius: 0;
  color: #c1c4d1;
  font-family: "IBM Plex Mono", monospace;
  width: 100%;
}

.form_input:focus {
  outline: 1px solid #ff00ff;
}

.form_button {
  align-self: end;
  font-family: "IBM Plex Mono", monospace;
  font-size: 1.2vw;
  font-weight: 600;
  background-color: #ff00ff;
  color: #fff;
  border: none;
  padding: 1% 3%;
  opacity: 0.75;
  transition: all 0.2s;
  cursor: pointer;
}

.form_button:hover {
  opacity: 1;
}

.footer_bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;
  gap: 2vw;
}

.footer_bottom_logo {
  width: 20%;
  filter: drop-shadow(0 0.1vw 0.3vw);
}

.footer_bottom_divider {
  width: 100%;
  height: 2px;
  background-color: #9b9daa;
}

.footer_bottom_text {
  color: #9b9daa;
  font-size: 0.6vw;
}

/* FAQ SECTION */

.overlay_faq {
  margin: 0;
  padding: 12%;
  padding-bottom: 20%;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background-color: #050a2b;
  color: #9b9daa;
  font-size: 1vw;
  font-family: "IBM Plex Mono", monospace;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  animation: fadeIn 0.5s ease-in;
}

.overlay_faq h1, .overlay_faq h2 {
  color: #c1c4d1;
}

.overlay_faq p {
  margin-bottom: 5%;
}

.overlay_faq span { 
  color: #c1c4d1;
  font-weight: bold;
}

.close {
  font-size: 4vw;
  font-weight: 400;
  position: fixed;
  top: 2%;
  right: 5%;
  cursor: pointer;
  transition: all 0.2s;
}

.close:hover {
  scale: 1.2;
}

@media only screen and (max-width: 1520px) {
  .loading_copy {
    font-size: 4vw;
  }

  .loading_button {
    font-size: 1.6vw;
  }

  .power_container {
    padding: 5%;
    font-size: 1.2vw;
  }

  .headline_button {
    font-size: 1.6vw;
  }

  .headline_copy_1,
  .intro_copy_heading,
  .right_copy_heading {
    font-size: 2.7vw;
  }

  .headline_copy_2,
  .headline_copy_3 {
    font-size: 2vw;
  }

  .intro_copy_line_1,
  .intro_copy_line_2,
  .intro_copy_line_3,
  .intro_copy_line_4 {
    font-size: 1.35vw;
  }

  .intro_copy_line_5 {
    font-size: 1.6vw;
  }

  .intro_point_1,
  .intro_point_2,
  .intro_point_3 {
    align-self: center;
    color: #9b9daa;
    font-size: 1.2vw;
    width: 80%;
  }

  .intro_box {
    font-size: 1.2vw;
  }

  .intro_box > p > span {
    font-size: 1.35vw;
  }

  .bullet_point {
    font-size: 1.05vw;
  }

  /* RIGHT SECTION */

  .right_copy > p {
    font-size: 1.35vw;
  }

  .label {
    font-size: 1.6vw;
    margin: 5% 5% 0;
  }

  .right_bullet_points > .bullet_point > .bullet_point_check {
    width: 4%;
  }

  .right_bullet_points > .bullet_point {
    align-items: start;
  }

  .right_ending > p {
    font-size: 1.35vw;
  }

  /* BOOST SECTION */

  .boost_section {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 5vw;
    margin-top: 10%;
  }

  .boost_heading {
    font-size: 2.7vw;
    margin: 0;
    font-family: "Oxanium", sans-serif;
    font-weight: 500;
    filter: drop-shadow(0 0.1vw 0.4vw);
  }

  .boost_container_section_1 {
    display: grid;
    margin: 0 10%;
    grid-template-columns: 1fr 1fr;
    row-gap: 2vw;
    padding: 5%;
    border: 2px dashed #ff00ff;
    background-color: rgba(244, 49, 109, 0.1);
    color: #9b9daa;
  }

  .boost_copy_1 {
    grid-column: 1 / -1;
    font-size: 1.35vw;
  }

  .boost_bullet_points_1 {
    grid-column: 1 / -1;
    width: 90%;
    justify-self: center;
  }

  .boost_bullet_point {
    display: flex;
    align-items: start;
    font-size: 1.05vw;
    margin-bottom: 2%;
  }

  .boost_bullet_point_img {
    width: 3%;
    margin-right: 1%;
  }

  .boost_video_container {
    display: flex;
    justify-content: center;
  }

  .boost_video_container > video {
    border: 2px solid #ff00ff;
  }

  .boost_before_title,
  .boost_after_title {
    font-size: 2.15vw;
    font-weight: 600;
    color: #ff00ff;
    font-family: "Oxanium", sans-serif;
    margin: auto;
    filter: drop-shadow(0 0.1vw 0.4vw);
  }

  .boost_container_section_2 {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 2vw;
    color: #9b9daa;
    align-items: center;
  }

  .boost_bullet_points_2 {
    width: 95%;
    margin: 3% auto;
  }

  .boost_bullet_points_2 > .boost_bullet_point > .boost_bullet_point_img {
    width: 3.5%;
    margin-right: 1%;
  }

  /* PACKAGES SECTION */

  .packages_heading {
    font-size: 2.7vw;
  }

  .package_bullet_point {
    font-size: 1.05vw;
  }

  .bullet_point_plus {
    margin-top: 1.5%;
  }

  .package_button {
    font-size: 1.6vw;
  }

  /* FOOTER SECTION */

  .footer_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer_buttons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2vw;
    margin-top: 5%;
    padding: 5% 0;
  }

  .footer_button {
    width: 100%;
    text-align: center;
    font-size: 1.6vw;
    font-weight: 600;
    opacity: 0.75;
    transition: all 0.2s;
    cursor: pointer;
    justify-self: center;
  }

  .footer_button > a {
    display: inline-block;
    padding: 2%;
    width: 100%;
    background-color: #9b9daa;
    text-decoration: none;
    color: white;
    transition: all 0.2s;
  }

  .footer_button:hover {
    opacity: 1;
    background-color: #c1c4d1;
  }

  .footer_button:hover > a {
    color: #050a2b;
  }

  .form_power_contact {
    width: 80%;
    padding: 5%;
    border: 2px dashed;
    margin-top: 10%;
    background-color: rgba(244, 49, 109, 0.1);
    display: flex;
    flex-direction: column;
  }

  .form_heading {
    font-size: 2.7vw;
    margin: 0 0 2%;
    font-family: "Oxanium", sans-serif;
    font-weight: 500;
    filter: drop-shadow(0 0.1vw 0.4vw);
  }

  .form_label {
    font-size: 1vw;
  }

  .form_input {
    font-size: 1.2vw;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    margin: 0.25% 0 2%;
    padding: 1%;
    color: #c1c4d1;
    font-family: "IBM Plex Mono", monospace;
    width: 100%;
  }

  .form_input:focus {
    outline: 1px solid #ff00ff;
  }

  .form_button {
    align-self: end;
    font-family: "IBM Plex Mono", monospace;
    font-size: 1.6vw;
    font-weight: 600;
    background-color: #ff00ff;
    color: #fff;
    border: none;
    padding: 1% 3%;
    opacity: 0.75;
    transition: all 0.2s;
    cursor: pointer;
  }

  .form_button:hover {
    opacity: 1;
  }

  .footer_bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15%;
    gap: 2vw;
  }

  .footer_bottom_logo {
    width: 25%;
    filter: drop-shadow(0 0.1vw 0.3vw);
  }

  .footer_bottom_divider {
    width: 100%;
    height: 2px;
    background-color: #9b9daa;
  }

  .footer_bottom_text {
    color: #9b9daa;
    font-size: 0.8vw;
  }

  /* FAQ SECTION */

  .overlay_faq {
    font-size: 1.4vw;
  }

  .close { 
    font-size: 6vw;
  }
}

@media only screen and (max-width: 1024px) {
  .loading_copy {
    font-size: 6vw;
    filter: drop-shadow(0 0.2vw 0.8vw #ff00ff);
    margin-bottom: 3%;
  }

  .loading_button {
    font-size: 2.4vw;
    padding: 2%;
  }

  .power_container {
    font-size: 1.8vw;
  }

  .cover_animation {
    width: 105%;
  }

  .headline_section {
    grid-template-columns: 1fr;
  }

  .headline_copy_1,
  .intro_copy_heading,
  .right_copy_heading {
    font-size: 4vw;
    filter: drop-shadow(0 0.2vw 0.8vw);
  }

  .headline_copy_2,
  .headline_copy_3 {
    font-size: 3vw;
  }

  .headline_right_container {
    width: 50%;
    padding: 5%;
    justify-self: center;
    margin-top: 5%;
  }

  .headline_button {
    width: 100%;
    font-size: 2.4vw;
    opacity: 1;
  }

  .headline_button > a {
    width: 100%;
    padding: 5%;
  }

  /* INTRO SECTION */

  .intro_section {
    grid-template-columns: 1fr;
    column-gap: 2vw;
    row-gap: 6vw;
    margin-bottom: 10%;
  }

  .intro_copy_heading {
    margin-bottom: 5%;
  }

  .intro_copy_line_1,
  .intro_copy_line_2,
  .intro_copy_line_3,
  .intro_copy_line_4,
  .intro_copy_line_5 {
    font-size: 2vw;
  }

  .intro_copy_line_5 {
    font-size: 2.4vw;
  }

  .intro_point_1,
  .intro_point_2,
  .intro_point_3 {
    justify-self: center;
    color: #9b9daa;
    font-size: 1.8vw;
    width: 100%;
  }

  .intro_point_2 {
    text-align: start;
  }

  .intro_lottie_1,
  .intro_lottie_2,
  .intro_lottie_3 {
    width: 60%;
    justify-self: center;
  }

  .intro_lottie_1 {
    grid-row: 2;
  }

  .intro_lottie_3 {
    grid-row: 6;
  }

  .intro_box {
    margin: 0;
    font-size: 1.8vw;
  }

  .intro_box > p > span {
    font-size: 2vw;
  }

  .bullet_points {
    grid-template-columns: 1fr;
  }

  .bullet_point {
    font-size: 1.6vw;
  }

  .bullet_point_check {
    width: 3%;
  }

  .right_section {
    grid-template-columns: 1fr;
  }

  .right_copy_heading {
    margin-bottom: 5%;
  }

  .right_copy > p {
    font-size: 2vw;
  }

  .label {
    font-size: 2.4vw;
    margin: 5% 0 0;
    padding: 1%;
  }

  .mobile_line_break {
    display: block;
  }

  .right_bullet_points > .bullet_point > .bullet_point_check {
    width: 3%;
  }

  .right_bullet_points > .bullet_point {
    align-items: start;
  }

  .right_lottie_1,
  .right_lottie_2 {
    width: 60%;
    justify-self: center;
  }

  .right_lottie_1 {
    grid-row: 2;
    margin-top: 10%;
  }

  .right_description_1 {
    margin: 0;
  }

  .right_description_2 {
    text-align: start;
  }

  .right_ending > .label {
    margin-bottom: 5%;
  }

  .right_ending > p {
    color: #9b9daa;
    font-size: 2vw;
  }

  /* BOOST SECTION */

  .boost_section {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 5vw;
    margin-top: 10%;
  }

  .boost_heading {
    font-size: 4vw;
    margin: 0;
    font-family: "Oxanium", sans-serif;
    font-weight: 500;
    filter: drop-shadow(0 0.2vw 0.8vw);
  }

  .boost_container_section_1 {
    display: grid;
    margin: 0;
    grid-template-columns: 1fr 1fr;
    row-gap: 2vw;
    padding: 5%;
    border: 2px dashed #ff00ff;
    background-color: rgba(244, 49, 109, 0.1);
    color: #9b9daa;
  }

  .boost_copy_1 {
    grid-column: 1 / -1;
    font-size: 2.1vw;
  }

  .boost_bullet_points_1 {
    grid-column: 1 / -1;
    width: 90%;
    justify-self: center;
  }

  .boost_bullet_point {
    display: flex;
    align-items: start;
    font-size: 1.6vw;
    margin-bottom: 2%;
  }

  .boost_bullet_point_img {
    width: 3%;
    margin-right: 1%;
  }

  .boost_video_container {
    display: flex;
    justify-content: center;
    /* width: 80%; */
    margin: auto;
  }

  .boost_video_container > video {
    border: 2px solid #ff00ff;
  }

  .boost_before_title,
  .boost_after_title {
    font-size: 3.2vw;
    filter: drop-shadow(0 0.2vw 0.8vw);
  }

  .boost_container_section_2 {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 2vw;
    color: #9b9daa;
    align-items: center;
  }

  .boost_bullet_points_2 {
    width: 95%;
    margin: 3% auto;
  }

  .boost_bullet_points_2 > .boost_bullet_point > .boost_bullet_point_img {
    width: 2.75%;
  }

  .boost_lottie {
    grid-row: 1;
    width: 60%;
    justify-self: center;
  }

  /* PACKAGES SECTION */

  .packages_heading {
    font-size: 4vw;
    filter: drop-shadow(0 0.2vw 0.8vw);
  }

  .package_container_section {
    grid-template-columns: 1fr;
    row-gap: 10vw;
    margin-top: 10%;
    width: 80%;
  }

  .package_container > img {
    width: 40%;
    filter: drop-shadow(0 0.2vw 0.8vw);
  }

  .package_container > h3 {
    font-size: 3vw;
    margin-bottom: 0;
  }

  .package_container > h4 {
    font-size: 2.4vw;
    margin-bottom: 10%;
  }

  .package_bullet_point {
    font-size: 1.6vw;
  }

  .bullet_point_plus {
    width: 3%;
    margin-top: 0.5%;
  }

  .package_button {
    font-size: 2.4vw;
    opacity: 1;
  }

  .package_button:hover {
    opacity: 1;
  }

  /* FOOTER SECTION */

  .footer_button {
    font-size: 2.4vw;
    opacity: 1;
  }

  .footer_button:hover {
    background-color: #9b9daa;
  }

  .footer_button:hover > a {
    color: white;
  }

  .footer_button:active > a, .faq_button:active {
    background-color: #c1c4d1;
    color: #050a2b;
  }

  .form_power_contact {
    width: 80%;
    padding: 5%;
    border: 2px dashed;
  }

  .form_heading {
    font-size: 4vw;
    filter: drop-shadow(0 0.2vw 0.8vw);
  }

  .form_label {
    font-size: 1.5vw;
  }

  .form_input {
    font-size: 1.8vw;
    padding: 2%;
  }

  .form_button {
    font-size: 2.4vw;
    padding: 1% 3%;
  }

  .form_button:hover {
    opacity: 0.75;
  }

  .form_button:active {
    opacity: 1;
  }

  .footer_bottom_logo {
    width: 30%;
    filter: drop-shadow(0 0.2vw 0.6vw);
  }

  .footer_bottom_text {
    font-size: 1.2vw;
  }

  /* FAQ SECTION */

  .overlay_faq {
    font-size: 2vw;
  }

  .close { 
    font-size: 8vw;
  }
}

@media only screen and (max-width: 500px) {
  .loading_copy {
    font-size: 8vw;
    margin-bottom: 5%;
    filter: drop-shadow(0 0.3vw 1.2vw);
  }

  .loading_button {
    font-size: 3.2vw;
    padding: 3%;
  }

  .power_container {
    font-size: 2.7vw;
  }

  .headline_copy_1,
  .intro_copy_heading,
  .right_copy_heading {
    font-size: 6vw;
    filter: drop-shadow(0 0.3vw 1.2vw);
  }

  .headline_copy_2,
  .headline_copy_3 {
    font-size: 4.5vw;
  }

  .headline_right_container {
    width: 50%;
    padding: 5%;
    justify-self: center;
    margin-top: 5%;
  }

  .headline_right_container {
    width: 80%;
  }

  .headline_button {
    font-size: 3.6vw;
    text-align: center;
  }

  .headline_button > a {
    display: inline-block;
  }

  .intro_copy_line_1,
  .intro_copy_line_2,
  .intro_copy_line_3,
  .intro_copy_line_4,
  .intro_copy_line_5 {
    font-size: 3vw;
  }

  .intro_copy_line_5 {
    font-size: 3.6vw;
  }

  .intro_point_1,
  .intro_point_2,
  .intro_point_3 {
    font-size: 2.7vw;
  }
  .intro_lottie_1,
  .intro_lottie_2,
  .intro_lottie_3 {
    width: 70%;
  }

  .intro_box {
    font-size: 2.7vw;
  }

  .intro_box > p > span {
    font-size: 3vw;
  }

  .bullet_point {
    font-size: 2.4vw;
  }

  .right_copy > p {
    font-size: 3vw;
  }

  .label {
    font-size: 3.6vw;
  }

  .right_bullet_points > .bullet_point > .bullet_point_check {
    width: 3%;
    margin-top: 0.5%;
  }

  .right_lottie_1,
  .right_lottie_2 {
    width: 70%;
  }

  .right_ending > .label {
    margin-bottom: 5%;
  }

  .right_ending > p {
    font-size: 3vw;
  }

  /* BOOST SECTION */

  .boost_heading {
    font-size: 6vw;
    filter: drop-shadow(0 0.3vw 1.2vw);
  }

  .boost_container_section_1 {
    grid-template-columns: 1fr;
    row-gap: 4vw;
  }

  .boost_copy_1 {
    font-size: 3.2vw;
  }

  .boost_bullet_point {
    font-size: 2.4vw;
  }

  .boost_bullet_point_img {
    width: 4%;
    margin-top: 0.5%;
  }

  .boost_before_title,
  .boost_after_title {
    font-size: 4.8vw;
    filter: drop-shadow(0 0.3vw 1.2vw);
  }

  .boost_after_title {
    grid-row: 5;
  }

  .boost_container_section_2 {
    gap: 4vw;
  }

  .boost_bullet_points_2 {
    width: 95%;
    margin: 3% auto;
  }

  .boost_bullet_points_2 > .boost_bullet_point > .boost_bullet_point_img {
    width: 3.5%;
    margin-top: -0.25%;
  }

  .boost_lottie {
    width: 70%;
  }

  /* PACKAGES SECTION */

  .package_container_section {
    width: 90%;
  }

  .packages_heading {
    font-size: 6vw;
    filter: drop-shadow(0 0.3vw 1.2vw);
  }

  .package_container > img {
    filter: drop-shadow(0 0.3vw 1.2vw);
  }

  .package_container > h3 {
    font-size: 4.5vw;
  }

  .package_container > h4 {
    font-size: 3.6vw;
  }

  .package_bullet_point {
    font-size: 2.4vw;
  }

  .bullet_point_plus {
    width: 3%;
    margin-right: 2%;
    margin-top: 1.5%;
  }

  .bullet_points_container {
    margin-bottom: 10%;
  }

  .package_button {
    font-size: 3.6vw;
    opacity: 1;
  }

  /* FOOTER SECTION */

  .footer_buttons {
    gap: 4vw;
    padding: 5% 0;
  }

  .footer_button {
    font-size: 3.6vw;
    opacity: 1;
  }

  .footer_button > a {
    padding: 5% 2%;
  }

  .footer_button:hover {
    background-color: #9b9daa;
  }

  .footer_button:hover > a {
    color: white;
  }

  .footer_button:active > a {
    background-color: #c1c4d1;
    color: #050a2b;
  }

  .form_power_contact {
    width: 90%;
    gap: 1vw;
  }

  .form_heading {
    font-size: 6vw;
    line-height: 1.1;
    filter: drop-shadow(0 0.3vw 1.2vw);
  }

  .form_label {
    font-size: 2.7vw;
  }

  .form_input {
    font-size: 3.2vw;
  }

  .form_button {
    font-size: 4.2vw;
    padding: 2% 5%;
  }

  .footer_bottom {
    gap: 4vw;
  }

  .footer_bottom_logo {
    width: 40%;
    filter: drop-shadow(0 0.3vw 0.9vw);
  }

  .footer_bottom_text {
    font-size: 1.8vw;
  }

  /* FAQ SECTION */

  .overlay_faq {
    font-size: 3vw;
  }

  .close { 
    font-size: 10vw;
  }
}
