.roof_container_mb {
  width: 100%;
  height: 90vh;
  position: relative;
  display: flex;
}

.roof_mb {
  align-self: flex-end;
  width: 100%;
}

.pp_button_lottie {
  position: absolute;
  bottom: -.4%;
  right: 5%;
  width: 35%;
}