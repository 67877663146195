.roof_container {
  position: absolute;
  top: -1.2%;
  left: 19.3%;
  width: 62.3%;
  text-align: center;
  z-index: 10;
}

.roof {
  position: relative;
  width: 100%;
}

.roof_lottie {
  width: 100%;
  left: 0;
}

.pp_button_lottie {
  position: absolute;
  bottom: 2%;
  right: 10%;
  width: 17.185%;
}
